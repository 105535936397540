var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('div', {
    staticClass: "body-content-overlay",
    class: {
      'show': _vm.mqShallShowLeftSidebar
    },
    on: {
      "click": function click($event) {
        _vm.mqShallShowLeftSidebar = false;
      }
    }
  }), _c('div', {
    staticClass: "todo-app-list"
  }, [_c('div', {
    staticClass: "app-fixed-search d-flex align-items-center"
  }, [_c('div', {
    staticClass: "sidebar-toggle d-block d-lg-none ml-1"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    },
    on: {
      "click": function click($event) {
        _vm.mqShallShowLeftSidebar = true;
      }
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-content-center justify-content-between w-100"
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "value": _vm.searchQuery,
      "placeholder": "Search task"
    },
    on: {
      "input": _vm.updateRouteQuery
    }
  })], 1)], 1), _c('div', {
    staticClass: "dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "variant": "link",
      "no-caret": "",
      "toggle-class": "p-0 mr-1",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "MoreVerticalIcon",
            "size": "16"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.resetSortAndNavigate
    }
  }, [_vm._v(" Reset Sort ")]), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, {
          sort: 'title-asc'
        })
      }
    }
  }, [_vm._v(" Sort A-Z ")]), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, {
          sort: 'title-desc'
        })
      }
    }
  }, [_vm._v(" Sort Z-A ")]), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, {
          sort: 'assignee'
        })
      }
    }
  }, [_vm._v(" Sort Assignee ")]), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, {
          sort: 'due-date'
        })
      }
    }
  }, [_vm._v(" Sort Due Date ")])], 1)], 1)]), _c('vue-perfect-scrollbar', {
    staticClass: "todo-task-list-wrapper list-group scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('draggable', {
    staticClass: "todo-task-list media-list",
    attrs: {
      "handle": ".draggable-task-handle",
      "tag": "ul"
    },
    model: {
      value: _vm.tasks,
      callback: function callback($$v) {
        _vm.tasks = $$v;
      },
      expression: "tasks"
    }
  }, _vm._l(_vm.tasks, function (task) {
    return _c('li', {
      key: task.id,
      staticClass: "todo-item",
      class: {
        'completed': task.isCompleted
      },
      on: {
        "click": function click($event) {
          return _vm.handleTaskClick(task);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "draggable-task-handle d-inline",
      attrs: {
        "icon": "MoreVerticalIcon"
      }
    }), _c('div', {
      staticClass: "todo-title-wrapper"
    }, [_c('div', {
      staticClass: "todo-title-area"
    }, [_c('div', {
      staticClass: "title-wrapper"
    }, [_c('b-form-checkbox', {
      attrs: {
        "checked": task.isCompleted
      },
      on: {
        "change": function change($event) {
          return _vm.updateTaskIsCompleted(task);
        }
      },
      nativeOn: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }), _c('span', {
      staticClass: "todo-title"
    }, [_vm._v(_vm._s(task.title))])], 1)]), _c('div', {
      staticClass: "todo-item-action"
    }, [_c('div', {
      staticClass: "badge-wrapper mr-1"
    }, _vm._l(task.tags, function (tag) {
      return _c('b-badge', {
        key: tag,
        staticClass: "text-capitalize",
        attrs: {
          "pill": "",
          "variant": "light-".concat(_vm.resolveTagVariant(tag))
        }
      }, [_vm._v(" " + _vm._s(tag) + " ")]);
    }), 1), _c('small', {
      staticClass: "text-nowrap text-muted mr-1"
    }, [_vm._v(_vm._s(_vm.formatDate(task.dueDate, {
      month: 'short',
      day: 'numeric'
    })))]), task.assignee ? _c('b-avatar', {
      attrs: {
        "size": "32",
        "src": task.assignee.avatar,
        "variant": "light-".concat(_vm.resolveAvatarVariant(task.tags)),
        "text": _vm.avatarText(task.assignee.fullName)
      }
    }) : _c('b-avatar', {
      attrs: {
        "size": "32",
        "variant": "light-secondary"
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "UserIcon",
        "size": "16"
      }
    })], 1)], 1)])], 1);
  }), 0), _c('div', {
    staticClass: "no-results",
    class: {
      'show': !_vm.tasks.length
    }
  }, [_c('h5', [_vm._v("No Items Found")])])], 1)], 1), _c('todo-task-handler-sidebar', {
    attrs: {
      "task": _vm.task,
      "clear-task-data": _vm.clearTaskData
    },
    on: {
      "remove-task": _vm.removeTask,
      "add-task": _vm.addTask,
      "update-task": _vm.updateTask
    },
    model: {
      value: _vm.isTaskHandlerSidebarActive,
      callback: function callback($$v) {
        _vm.isTaskHandlerSidebarActive = $$v;
      },
      expression: "isTaskHandlerSidebarActive"
    }
  }), _c('portal', {
    attrs: {
      "to": "content-renderer-sidebar-left"
    }
  }, [_c('todo-left-sidebar', {
    class: {
      'show': _vm.mqShallShowLeftSidebar
    },
    attrs: {
      "task-tags": _vm.taskTags,
      "is-task-handler-sidebar-active": _vm.isTaskHandlerSidebarActive
    },
    on: {
      "update:isTaskHandlerSidebarActive": function updateIsTaskHandlerSidebarActive($event) {
        _vm.isTaskHandlerSidebarActive = $event;
      },
      "update:is-task-handler-sidebar-active": function updateIsTaskHandlerSidebarActive($event) {
        _vm.isTaskHandlerSidebarActive = $event;
      },
      "close-left-sidebar": function closeLeftSidebar($event) {
        _vm.mqShallShowLeftSidebar = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }