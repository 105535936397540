var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-left"
  }, [_c('div', {
    staticClass: "sidebar"
  }, [_c('div', {
    staticClass: "sidebar-content todo-sidebar"
  }, [_c('div', {
    staticClass: "todo-app-menu"
  }, [_c('div', {
    staticClass: "add-task"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.$emit('update:is-task-handler-sidebar-active', true);

        _vm.$emit('close-left-sidebar');
      }
    }
  }, [_vm._v(" Add Task ")])], 1), _c('vue-perfect-scrollbar', {
    staticClass: "sidebar-menu-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-list-group', {
    staticClass: "list-group-filters"
  }, _vm._l(_vm.taskFilters, function (filter) {
    return _c('b-list-group-item', {
      key: filter.title + _vm.$route.path,
      attrs: {
        "to": filter.route,
        "active": _vm.isDynamicRouteActive(filter.route)
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('close-left-sidebar');
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-75",
      attrs: {
        "icon": filter.icon,
        "size": "18"
      }
    }), _c('span', {
      staticClass: "align-text-bottom line-height-1"
    }, [_vm._v(_vm._s(filter.title))])], 1);
  }), 1), _c('div', {
    staticClass: "mt-3 px-2 d-flex justify-content-between"
  }, [_c('h6', {
    staticClass: "section-label mb-1"
  }, [_vm._v(" Tags ")]), _c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1), _c('b-list-group', {
    staticClass: "list-group-labels"
  }, _vm._l(_vm.taskTags, function (tag) {
    return _c('b-list-group-item', {
      key: tag.title + _vm.$route.path,
      attrs: {
        "to": tag.route,
        "active": _vm.isDynamicRouteActive(tag.route)
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('close-left-sidebar');
        }
      }
    }, [_c('span', {
      staticClass: "bullet bullet-sm mr-1",
      class: "bullet-".concat(tag.color)
    }), _c('span', [_vm._v(_vm._s(tag.title))])]);
  }), 1)], 1)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }